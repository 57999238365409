var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return !_vm.loadPage
    ? _c(
        "div",
        { staticClass: "calculator-container" },
        [
          _c("BackNav", {
            on: {
              click: function ($event) {
                return _vm.$router.go(-1)
              },
            },
          }),
          _c(
            "h1",
            { staticClass: "mt-2 text-dark text-lg md:text-xl font-bold" },
            [_vm._v("Cost Calculator")]
          ),
          _c("p", { staticClass: "text-sm text-lightGrey-2" }, [
            _vm._v(
              "Calculate your instalment payments when making a purchase."
            ),
          ]),
          _c(
            "div",
            { staticClass: "calculator-form mt-6" },
            [
              _c("Card", { attrs: { radius: 16 } }, [
                _c("div", { staticClass: "card-heading text-center" }, [
                  _c(
                    "p",
                    { staticClass: "text-center top font-bold text-dark" },
                    [
                      _vm._v(
                        " " +
                          _vm._s(_vm._f("formatMoney")(_vm.productAmount)) +
                          " "
                      ),
                    ]
                  ),
                  (_vm.paymentType === "creditCard" &&
                    _vm.selectedPayment.feeDetails.fees[0].percentage > 0) ||
                  _vm.selectedPayment.feeDetails.fees[0].percentage > 0
                    ? _c(
                        "p",
                        {
                          staticClass:
                            "text-xs md:text-sm text-lightGrey-2 text-center fees",
                        },
                        [
                          _vm._v(
                            " (+" +
                              _vm._s(
                                _vm._f("formatMoney")(
                                  _vm.calulateFees(
                                    _vm.selectedPayment.feeDetails.fees
                                  )
                                )
                              ) +
                              ") "
                          ),
                          _c(
                            "span",
                            {
                              directives: [
                                {
                                  name: "tooltip",
                                  rawName: "v-tooltip",
                                  value: {
                                    content: _vm.tooltipContent(),
                                    placement: "bottom",
                                    trigger: "click",
                                  },
                                  expression:
                                    "{\n            content: tooltipContent(),\n            placement: 'bottom',\n            trigger: 'click',\n          }",
                                },
                              ],
                            },
                            [
                              _c("svg-icon", {
                                staticClass: "inline-block mb-1",
                                attrs: {
                                  color: "#918E9C",
                                  size: "15",
                                  type: "mdi",
                                  path: _vm.svgPath.info,
                                },
                              }),
                            ],
                            1
                          ),
                        ]
                      )
                    : _vm._e(),
                ]),
                _c("div", { staticClass: "timeline" }, [
                  _c(
                    "div",
                    {
                      staticClass: "timeline-grid grid",
                      style: {
                        "grid-template-columns":
                          "repeat(" +
                          _vm.currentPaymentSchedule.length +
                          ", 1fr)",
                      },
                    },
                    _vm._l(_vm.currentPaymentSchedule, function (item, index) {
                      return _c(
                        "div",
                        { key: item + index, staticClass: "timeline-item" },
                        [
                          _c(
                            "div",
                            { staticClass: "timeline-amount text-center" },
                            [
                              _c("p", { staticClass: "text-lightGrey-2" }, [
                                _vm._v(
                                  _vm._s(
                                    _vm._f("formatMoney")(
                                      _vm.getAmountToPay(
                                        item.paymentAmountPercent,
                                        index
                                      )
                                    )
                                  )
                                ),
                              ]),
                            ]
                          ),
                          _c(
                            "div",
                            { staticClass: "timeline-stretch w-full" },
                            [
                              _c("div", {
                                staticClass: "line",
                                class: { "opacity-0": index === 0 },
                              }),
                              _c("div", { staticClass: "dot" }),
                              _c("div", {
                                staticClass: "line",
                                class: {
                                  "opacity-0":
                                    index ===
                                    _vm.currentPaymentSchedule.length - 1,
                                },
                              }),
                            ]
                          ),
                          _c("div", { staticClass: "timeline-duration" }, [
                            index === 0
                              ? _c(
                                  "p",
                                  {
                                    staticClass:
                                      "text-xs md:text-sm text-center text-lightGrey-2",
                                    class: { "text-brandRed": index === 0 },
                                  },
                                  [_vm._v("Pay now")]
                                )
                              : _c(
                                  "p",
                                  {
                                    staticClass:
                                      "text-xs md:text-sm text-center text-lightGrey-2",
                                  },
                                  [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          _vm._f("formatDueDate")(
                                            item.paymentDueAt
                                          )
                                        ) +
                                        " "
                                    ),
                                  ]
                                ),
                          ]),
                        ]
                      )
                    }),
                    0
                  ),
                ]),
                _vm.paymentType === "creditCard"
                  ? _c("div", { staticClass: "heading-description" }, [
                      _c("p", { staticClass: "text-lightGrey-2 text-xs" }, [
                        _c("span", { staticClass: "text-blue" }, [
                          _vm._v("Note:"),
                        ]),
                        _vm._v(
                          " First instalment will be charged from your wallet. You need to have "
                        ),
                        _c("span", { staticClass: "font-semibold" }, [
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm._f("formatMoney")(
                                  _vm.getAmountToPay(
                                    _vm.currentPaymentSchedule[0]
                                      .paymentAmountPercent,
                                    0
                                  )
                                )
                              )
                          ),
                        ]),
                        _vm._v(
                          " in your wallet to be able to make this purchase. "
                        ),
                      ]),
                    ])
                  : _vm._e(),
              ]),
              _c(
                "Card",
                { staticClass: "mt-4 mb-8 md:mb-0", attrs: { radius: 16 } },
                [
                  _c(
                    "div",
                    [
                      _c("div", [
                        _c("p", { staticClass: "text-lightGrey-2" }, [
                          _vm._v("How much is the product?"),
                        ]),
                        _c("p", { staticClass: "text-orange text-xs" }, [
                          _vm._v(_vm._s(_vm.productErrorMessage)),
                        ]),
                      ]),
                      _c("vue-numeric", {
                        staticClass:
                          "currency-input-custom-class text-grey w-full my-2",
                        attrs: {
                          "output-type": "String",
                          "empty-value": _vm.amount.min,
                          currency: "₦",
                          min: _vm.amount.min,
                          max: _vm.amount.max,
                          separator: ",",
                        },
                        model: {
                          value: _vm.productAmount,
                          callback: function ($$v) {
                            _vm.productAmount = $$v
                          },
                          expression: "productAmount",
                        },
                      }),
                      _c("p", { staticClass: "text-xs text-lightGrey-2" }, [
                        _vm._v(
                          "It should be between " +
                            _vm._s(_vm._f("formatMoney")(_vm.amount.min)) +
                            " and " +
                            _vm._s(_vm._f("formatMoney")(_vm.amount.max))
                        ),
                      ]),
                    ],
                    1
                  ),
                  _c("div", { staticClass: "mt-6" }, [
                    _c("p", { staticClass: "text-lightGrey-2" }, [
                      _vm._v("What plan would you like to use?"),
                    ]),
                    _c(
                      "div",
                      {
                        staticClass:
                          "flex justify-between items-center custom-select cursor-pointer p-3 mt-2",
                        on: {
                          click: function ($event) {
                            return _vm.handlePaymentType()
                          },
                        },
                      },
                      [
                        _c("p", { staticClass: "text-lightGrey-2" }, [
                          _vm._v(_vm._s(_vm.activePaymentOption)),
                        ]),
                        _c(
                          "svg",
                          {
                            attrs: {
                              xmlns: "http://www.w3.org/2000/svg",
                              width: "8",
                              height: "5",
                              viewBox: "0 0 8 5",
                              fill: "none",
                            },
                          },
                          [
                            _c("path", {
                              attrs: {
                                d: "M1.4134 0.726494L4.00006 3.31316L6.58673 0.726494C6.84673 0.466494 7.26673 0.466494 7.52673 0.726494C7.78673 0.986494 7.78673 1.40649 7.52673 1.66649L4.46673 4.72649C4.20673 4.98649 3.78673 4.98649 3.52673 4.72649L0.466729 1.66649C0.206729 1.40649 0.206729 0.986494 0.466729 0.726494C0.726728 0.473161 1.1534 0.466494 1.4134 0.726494Z",
                                fill: "#918E9C",
                              },
                            }),
                          ]
                        ),
                      ]
                    ),
                  ]),
                  _vm.activePaymentOption ===
                  _vm.config[_vm.paymentType].flexiblePaymentPlanConfig.name
                    ? _c("div", [
                        _c("div", { staticClass: "mt-6" }, [
                          _c("p", { staticClass: "text-lightGrey-2" }, [
                            _vm._v("How much do you want to pay?"),
                          ]),
                          _c("div", { staticClass: "range-wrap mt-8" }, [
                            _c("div", {
                              staticClass: "range-value",
                              attrs: { id: "rangeV" },
                            }),
                            _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: _vm.flexiConfig.upfrontAmountPercent,
                                  expression:
                                    "flexiConfig.upfrontAmountPercent",
                                },
                              ],
                              ref: "ran",
                              staticClass: "range w-full",
                              attrs: {
                                id: "range",
                                type: "range",
                                step: _vm.config[_vm.paymentType]
                                  .flexiblePaymentPlanConfig
                                  .minimumUpfrontAmountPercentageStep,
                                min: _vm.flexiUpfrontAmountPercent,
                                max: 100,
                              },
                              domProps: {
                                value: _vm.flexiConfig.upfrontAmountPercent,
                              },
                              on: {
                                input: _vm.handleInputChange,
                                __r: function ($event) {
                                  return _vm.$set(
                                    _vm.flexiConfig,
                                    "upfrontAmountPercent",
                                    $event.target.value
                                  )
                                },
                              },
                            }),
                          ]),
                          _c(
                            "div",
                            {
                              staticClass:
                                "range-amount flex justify-between items-center",
                            },
                            [
                              _c(
                                "p",
                                { staticClass: "text-xs text-lightGrey-2" },
                                [
                                  _vm._v(
                                    _vm._s(_vm.flexiUpfrontAmountPercent) + "%"
                                  ),
                                ]
                              ),
                              _c(
                                "p",
                                { staticClass: "text-xs text-lightGrey-2" },
                                [_vm._v("100%")]
                              ),
                            ]
                          ),
                        ]),
                        _c(
                          "div",
                          { staticClass: "mt-6" },
                          [
                            _c("p", { staticClass: "text-lightGrey-2 mt-2" }, [
                              _vm._v("Select duration"),
                            ]),
                            _c("Select", {
                              staticClass: "mb-1 mt-2",
                              attrs: {
                                options: _vm.durationOptions,
                                reduce: function (value) {
                                  return value.value
                                },
                              },
                              model: {
                                value: _vm.flexiConfig.numberOfRepayment,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.flexiConfig,
                                    "numberOfRepayment",
                                    $$v
                                  )
                                },
                                expression: "flexiConfig.numberOfRepayment",
                              },
                            }),
                            _c(
                              "p",
                              {
                                staticClass: "text-grey text-xs",
                                class: {
                                  invisible:
                                    _vm.selectedPayment.feeDetails.fees[0]
                                      .percentage === 0,
                                },
                              },
                              [
                                _vm._v(
                                  " This is a one-time fee for entire repayment period. Not a monthly fee."
                                ),
                              ]
                            ),
                          ],
                          1
                        ),
                      ])
                    : _vm._e(),
                  _c("div", { staticClass: "checkout-mode mt-5" }, [
                    _c(
                      "div",
                      {
                        staticClass:
                          "flex items-center justify-between md:justify-start",
                      },
                      [
                        _c(
                          "p",
                          { staticClass: "text-xs text-lightGrey-2 md:mr-12" },
                          [_vm._v("PAYING WITH #CARDZILLA?")]
                        ),
                        _c("ToggleSwitch", {
                          attrs: { id: "cardtype" },
                          model: {
                            value: _vm.useCardZilla,
                            callback: function ($$v) {
                              _vm.useCardZilla = $$v
                            },
                            expression: "useCardZilla",
                          },
                        }),
                      ],
                      1
                    ),
                    _vm.config.creditBalance >= 0
                      ? _c(
                          "div",
                          {
                            staticClass:
                              "flex items-center mt-4 justify-between md:justify-start",
                          },
                          [
                            _c(
                              "p",
                              {
                                staticClass:
                                  "text-xs text-lightGrey-2 md:mr-10",
                              },
                              [
                                _vm._v(
                                  "LET THE RESULT BE BASED ON MY CREDIT BALANCE"
                                ),
                              ]
                            ),
                            _c("ToggleSwitch", {
                              attrs: { id: "credit balance" },
                              model: {
                                value: _vm.applyUserCreditBalance,
                                callback: function ($$v) {
                                  _vm.applyUserCreditBalance = $$v
                                },
                                expression: "applyUserCreditBalance",
                              },
                            }),
                          ],
                          1
                        )
                      : _vm._e(),
                  ]),
                ]
              ),
            ],
            1
          ),
          _c(
            "Modal",
            {
              attrs: { display: _vm.showModal, title: "Select payment plan" },
              on: {
                close: function ($event) {
                  _vm.showModal = false
                },
              },
            },
            [
              _vm._l(
                _vm.config[_vm.paymentType].paymentPlans,
                function (payment) {
                  return payment.name !== "Pay in full"
                    ? _c(
                        "div",
                        {
                          key: payment.name,
                          staticClass: "mt-6 mb-7 cursor-pointer",
                          class: [_vm.disablePlan(payment)],
                          on: {
                            click: function ($event) {
                              return _vm.selectPayment(payment.name)
                            },
                          },
                        },
                        [
                          _c("div", [
                            _c("div", { staticClass: "payment-options" }, [
                              _c("div", [
                                _vm.activePaymentOption === payment.name
                                  ? _c("img", {
                                      staticClass: "dot-active",
                                      attrs: {
                                        src: require("@/assets/icons/selected.svg"),
                                        alt: "",
                                      },
                                    })
                                  : _c("div", { staticClass: "dot" }),
                              ]),
                              _c("div", [
                                _c(
                                  "p",
                                  {
                                    staticClass:
                                      "text-lg font-semibold text-grey",
                                  },
                                  [_vm._v(_vm._s(payment.name))]
                                ),
                                _c("p", { staticClass: "text-sm text-grey" }, [
                                  _vm._v(_vm._s(payment.description)),
                                ]),
                              ]),
                            ]),
                          ]),
                        ]
                      )
                    : _vm._e()
                }
              ),
              _c(
                "div",
                {
                  staticClass: "mt-6 mb-7 cursor-pointer",
                  on: {
                    click: function ($event) {
                      return _vm.selectPayment(
                        _vm.config[_vm.paymentType].flexiblePaymentPlanConfig
                          .name
                      )
                    },
                  },
                },
                [
                  _c("div", { staticClass: "payment-options" }, [
                    _c("div", [
                      _vm.activePaymentOption ===
                      _vm.config[_vm.paymentType].flexiblePaymentPlanConfig.name
                        ? _c("img", {
                            staticClass: "dot-active",
                            attrs: {
                              src: require("@/assets/icons/selected.svg"),
                              alt: "",
                            },
                          })
                        : _c("div", { staticClass: "dot" }),
                    ]),
                    _c("div", [
                      _c(
                        "p",
                        {
                          staticClass:
                            "text-lg font-semibold text-grey flex items-center",
                        },
                        [
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm.config[_vm.paymentType]
                                  .flexiblePaymentPlanConfig.name
                              ) +
                              " "
                          ),
                          _c(
                            "span",
                            {
                              staticClass:
                                "px-3 py-1 text-white bg-brandPink rounded-lg text-xs ml-2",
                            },
                            [_vm._v("New")]
                          ),
                        ]
                      ),
                      _c("p", { staticClass: "text-sm text-grey" }, [
                        _vm._v(
                          _vm._s(
                            _vm.config[_vm.paymentType]
                              .flexiblePaymentPlanConfig.description
                          )
                        ),
                      ]),
                    ]),
                  ]),
                ]
              ),
            ],
            2
          ),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }