<template>
  <div v-if="!loadPage" class="calculator-container">
    <BackNav @click="$router.go(-1)" />
    <h1 class="mt-2 text-dark text-lg md:text-xl font-bold">Cost Calculator</h1>
    <p class="text-sm text-lightGrey-2">Calculate your instalment payments when making a purchase.</p>
    <div class="calculator-form mt-6">
      <Card :radius="16">
        <div class="card-heading text-center">
          <p class="text-center top font-bold text-dark">
            {{ productAmount | formatMoney }}
          </p>
          <p v-if="(paymentType === 'creditCard' && selectedPayment.feeDetails.fees[0].percentage > 0) || selectedPayment.feeDetails.fees[0].percentage > 0"
            class="text-xs md:text-sm text-lightGrey-2 text-center fees">
            (+{{ calulateFees(selectedPayment.feeDetails.fees) | formatMoney }})
            <span v-tooltip="{
              content: tooltipContent(),
              placement: 'bottom',
              trigger: 'click',
            }">
              <svg-icon class="inline-block mb-1" color="#918E9C" size="15" type="mdi" :path="svgPath.info"></svg-icon>
            </span>
          </p>
        </div>
        <div class="timeline">
          <div class="timeline-grid grid"
            :style="{ 'grid-template-columns': `repeat(${currentPaymentSchedule.length}, 1fr)` }">
            <div class="timeline-item" v-for="(item, index) in currentPaymentSchedule" :key="item + index">
              <div class="timeline-amount text-center">
                <p class="text-lightGrey-2">{{ getAmountToPay(item.paymentAmountPercent, index) | formatMoney }}</p>
              </div>
              <div class="timeline-stretch w-full">
                <div class="line" :class="{ 'opacity-0': index === 0 }"></div>
                <div class="dot"></div>
                <div class="line" :class="{ 'opacity-0': index === currentPaymentSchedule.length - 1 }"></div>
              </div>
              <div class="timeline-duration">
                <p class="text-xs md:text-sm text-center text-lightGrey-2" :class="{ 'text-brandRed': index === 0 }"
                  v-if="index === 0">Pay now</p>
                <p class="text-xs md:text-sm text-center text-lightGrey-2" v-else>
                  {{ item.paymentDueAt | formatDueDate }}
                </p>
              </div>
            </div>
          </div>
        </div>
        <div class="heading-description" v-if="paymentType === 'creditCard'">
          <p class="text-lightGrey-2 text-xs">
            <span class="text-blue">Note:</span> First instalment will be charged from your wallet. You need to have
            <span class="font-semibold">
              {{ getAmountToPay(currentPaymentSchedule[0].paymentAmountPercent, 0) | formatMoney }}</span>
            in your wallet to be able to make this purchase.
          </p>
        </div>
      </Card>

      <Card :radius="16" class="mt-4 mb-8 md:mb-0">
        <div>
          <div>
            <p class="text-lightGrey-2">How much is the product?</p>
            <p class="text-orange text-xs">{{ productErrorMessage }}</p>
          </div>
          <vue-numeric class="currency-input-custom-class text-grey w-full my-2" output-type="String"
            :empty-value="amount.min" currency="₦" :min="amount.min" :max="amount.max" separator=","
            v-model="productAmount"></vue-numeric>
          <p class="text-xs text-lightGrey-2">It should be between {{ amount.min | formatMoney }} and {{ amount.max |
            formatMoney }}</p>
        </div>

        <div class="mt-6">
          <p class="text-lightGrey-2">What plan would you like to use?</p>
          <div @click="handlePaymentType()"
            class="flex justify-between items-center custom-select cursor-pointer p-3 mt-2">
            <p class="text-lightGrey-2">{{
              activePaymentOption
            }}</p>
            <svg xmlns="http://www.w3.org/2000/svg" width="8" height="5" viewBox="0 0 8 5" fill="none">
              <path
                d="M1.4134 0.726494L4.00006 3.31316L6.58673 0.726494C6.84673 0.466494 7.26673 0.466494 7.52673 0.726494C7.78673 0.986494 7.78673 1.40649 7.52673 1.66649L4.46673 4.72649C4.20673 4.98649 3.78673 4.98649 3.52673 4.72649L0.466729 1.66649C0.206729 1.40649 0.206729 0.986494 0.466729 0.726494C0.726728 0.473161 1.1534 0.466494 1.4134 0.726494Z"
                fill="#918E9C" />
            </svg>
          </div>
        </div>

        <div v-if="activePaymentOption === config[paymentType].flexiblePaymentPlanConfig.name">
          <div class="mt-6">
            <p class="text-lightGrey-2">How much do you want to pay?</p>
            <div class="range-wrap mt-8">
              <div class="range-value" id="rangeV"></div>
              <input id="range" class="range w-full" type="range"
                :step="config[paymentType].flexiblePaymentPlanConfig.minimumUpfrontAmountPercentageStep"
                :min="flexiUpfrontAmountPercent" :max="100" v-model="flexiConfig.upfrontAmountPercent"
                @input="handleInputChange" ref="ran" />
            </div>
            <div class="range-amount flex justify-between items-center">
              <p class="text-xs text-lightGrey-2">{{ flexiUpfrontAmountPercent }}%</p>
              <p class="text-xs text-lightGrey-2">100%</p>
            </div>
          </div>

          <div class="mt-6">
            <p class="text-lightGrey-2 mt-2">Select duration</p>
            <Select class="mb-1 mt-2" :options="durationOptions" v-model="flexiConfig.numberOfRepayment"
              :reduce="(value) => value.value" />
            <p :class="{ 'invisible': selectedPayment.feeDetails.fees[0].percentage === 0 }" class="text-grey text-xs">
              This
              is a one-time fee for entire repayment period. Not a monthly fee.</p>
          </div>
        </div>

        <div class="checkout-mode mt-5">
          <div class="flex items-center justify-between md:justify-start">
            <p class="text-xs text-lightGrey-2 md:mr-12">PAYING WITH #CARDZILLA?</p>
            <ToggleSwitch v-model="useCardZilla" id="cardtype" />
          </div>
          <div class="flex items-center mt-4 justify-between md:justify-start" v-if="config.creditBalance >= 0">
            <p class="text-xs text-lightGrey-2 md:mr-10">LET THE RESULT BE BASED ON MY CREDIT BALANCE</p>
            <ToggleSwitch v-model="applyUserCreditBalance" id="credit balance" />
          </div>
        </div>
      </Card>
    </div>
    <Modal :display="showModal" title="Select payment plan" @close="showModal = false">
      <div v-if="payment.name !== 'Pay in full'" :class="[disablePlan(payment)]" class="mt-6 mb-7 cursor-pointer"
        v-for="payment in config[paymentType].paymentPlans" @click="selectPayment(payment.name)" :key="payment.name">
        <div>
          <div class="payment-options">
            <div>
              <img src="@/assets/icons/selected.svg" alt="" class="dot-active"
                v-if="activePaymentOption === payment.name" />
              <div class="dot" v-else></div>
            </div>
            <div>
              <p class="text-lg font-semibold text-grey">{{ payment.name }}</p>
              <p class="text-sm text-grey">{{ payment.description }}</p>
            </div>
          </div>
        </div>
      </div>

      <!-- <Hr /> -->
      <div class="mt-6 mb-7 cursor-pointer" @click="selectPayment(config[paymentType].flexiblePaymentPlanConfig.name)">
        <div class="payment-options">
          <div>
            <img src="@/assets/icons/selected.svg" alt="" class="dot-active"
              v-if="activePaymentOption === config[paymentType].flexiblePaymentPlanConfig.name" />
            <div class="dot" v-else></div>
          </div>
          <div>
            <p class="text-lg font-semibold text-grey flex items-center">
              {{ config[paymentType].flexiblePaymentPlanConfig.name }}
              <span class="px-3 py-1 text-white bg-brandPink rounded-lg text-xs ml-2">New</span>
            </p>
            <p class="text-sm text-grey">{{ config[paymentType].flexiblePaymentPlanConfig.description }}</p>
          </div>
        </div>
      </div>
    </Modal>
  </div>
</template>
<script>
import { mapActions, mapState } from "vuex";
import { BackNav } from "@/UI/Navigation";
import { Input, ToggleSwitch, CurrencyInput, Select } from "@/UI/Input";
import { mdiChevronDown } from "@mdi/js";
import { PAYMENT_PLANS } from "@/utils/sharedData/zillaCards";
import Tabs from "@/UI/Tab/Tab";
import { GET_CALCULATOR_DATA } from "@/utils/api/purchase";
import { mdiInformationOutline } from "@mdi/js";
import { Modal } from "@/UI/Modals";
import { Button } from "@/UI/Button";
import AnimateHeight from "@/UI/AnimateHeight";
import dayjs from "dayjs";
import VueNumeric from 'vue-numeric'
export default {
  components: {
    BackNav,
    Input,
    Tabs,
    ToggleSwitch,
    Modal,
    Button,
    AnimateHeight,
    CurrencyInput,
    Select,
    VueNumeric
  },
  data: () => ({
    showPaymentPlan: false,
    paymentOptions: PAYMENT_PLANS,
    svgPath: {
      arrowDown: mdiChevronDown,
    },
    productAmount: 5000,
    amount: {
      min: 5000,
      max: 2000000
    },
    useCardZilla: true,
    applyUserCreditBalance: true,
    activePaymentOption: "Flexible payment",
    svgPath: {
      info: mdiInformationOutline,
    },
    showModal: false,
    defaultCreditBalance: 300000,
    flexiblePaymentOptions: [],
    loadPage: null,
    flexiConfig: {
      upfrontAmountPercent: 25,
      numberOfRepayment: 1,
    },
  }),
  computed: {
    ...mapState({
      config: (state) => state.card?.calculatorData || {},
      allCreditCardFees: (state) => state?.userInfo?.allCreditCardFees,
      allCheckoutFees: (state) => state?.userInfo?.allCheckoutFees,
    }),

    durationOptions() {
      let options = [];
      for (let i = this.config[this.paymentType].flexiblePaymentPlanConfig.repaymentDuration.minimumValue; i <= this.config[this.paymentType].flexiblePaymentPlanConfig.repaymentDuration.maximumValue; i++) {
        let source = this.useCardZilla ? this.allCreditCardFees : this.allCheckoutFees;
        let fee;
        let interest;
        if (this.flexiUpfrontAmountPercent >= this.updatedUpfrontAmountPercentage) {
          fee = source[this.flexiUpfrontAmountPercent][i - 1].fees[0].percentage;
          interest = source[this.flexiUpfrontAmountPercent][i - 1];
        } else {
          fee = source[this.updatedUpfrontAmountPercentage][i - 1].fees[0].percentage;
          interest = source[this.updatedUpfrontAmountPercentage][i - 1]
        }
        let data = {
          label: `${i} month${i > 1 ? "s" : ""}   <span class="text-sm ml-3 text-lightGrey-2">(${fee}% fee)</span>`,
          value: i,
          interest: interest,
        };
        options.push(data);
      }
      return options;
    },

    // productAmount rules
    product() {
      return {
        // Rule to check if upfront amount is up to minimum amount and not more than product amount
        amount: this.productAmount >= this.amount.min && this.productAmount <= this.amount.max,
      };
    },

    // Return error message for currency input product amount
    productErrorMessage() {
      let msg;
      if (this.productAmount < this.amount.min) {
        msg = `Product amount must be greater than ${this.formatMoney(this.amount.min)}`;
      } else if (this.productAmount > this.amount.max) {
        msg = `Product amount must be less than ${this.formatMoney(this.amount.max)}`;
      } else {
        msg = null;
      }
      return msg;
    },

    // rename payment plans with default names on @/utils/sharedData/zillaCards
    activePaymentType() {
      let type;
      switch (this.activePaymentOption) {
        case this.config[this.paymentType].flexiblePaymentPlanConfig.name:
          type = PAYMENT_PLANS.flexible.toLowerCase();
          break;
        case "Pay in 3":
          type = PAYMENT_PLANS.thrice.toLowerCase();
          break;
        case "Pay twice":
          type = PAYMENT_PLANS.twice.toLowerCase();
          break;
      }
      return type;
    },

    // selected payment method object return for store for flexible and default
    selectedPayment() {
      if (this.activePaymentType === PAYMENT_PLANS.flexible.toLowerCase()) {
        return this.config[this.paymentType].flexiblePaymentPlanConfig;
      } else {
        return this.config[this.paymentType].paymentPlans.find((item) => item.name === this.activePaymentOption);
      }
    },

    // Payment method if checkout or credit card
    paymentType() {
      return this.useCardZilla ? "creditCard" : "checkout";
    },

    // Current repoayment schedule to display for select payment option/method
    currentPaymentSchedule() {
      if (this.activePaymentType === PAYMENT_PLANS.flexible.toLowerCase()) {
        return this.flexiblePaymentSchedule;
      } else {
        return this.selectedPayment.paymentSchedule;
      }
    },

    // Repayment for flexible payment computed using repayment duration
    flexiblePaymentSchedule() {
      let remainingPayments = [];
      // split the payments based on the number of installment chosen
      let percentage = (100 - this.flexiConfig.upfrontAmountPercent) / this.flexiConfig.numberOfRepayment;

      // loop over the number of instalment chosen and generate data for each
      for (let i = 0; i < this.flexiConfig.numberOfRepayment; i++) {
        let data = {
          paymentAmountPercent: percentage,
          paymentDueAt: this.calculateRepaymentDate(
            new Date(),
            "after",
            this.selectedPayment.repaymentDuration.timeUnit,
            i + 1
          ),
        };
        remainingPayments.push(data);
      }
      const data = [
        {
          paymentAmountPercent: this.flexiConfig.upfrontAmountPercent,
          paymentDueAt: new Date(),
        },
        ...remainingPayments,
      ];

      return data;
    },

    // Amount we can finance for the product depending on the product amounr
    creditAmountFinanceable() {
      let amountFinanceable;
      if (this.activePaymentType === PAYMENT_PLANS.twice.toLowerCase() || this.activePaymentType === PAYMENT_PLANS.flexible.toLowerCase()) {
        amountFinanceable = (75 / 100) * this.totalProductAmount();
      } else if (this.activePaymentType === PAYMENT_PLANS.thrice.toLowerCase()) {
        amountFinanceable = (50 / 100) * this.totalProductAmount();
      } else {
        amountFinanceable = 0;
      }
      return amountFinanceable;
    },

    // Use customer credit balance or assume full credit balance
    creditBalanceToUse() {
      return this.applyUserCreditBalance ? this.config?.creditBalance : this.defaultCreditBalance;
    },

    // Product amount plus interest
    updatedUpfrontAmountPercentage() {
      return this.flexiConfig.upfrontAmountPercent;
    },

    flexiUpfrontAmountPercent() {
      let number = (this.productAmount - this.creditBalanceToUse) * 100 / this.productAmount;
      let divisor = this.config[this.paymentType].flexiblePaymentPlanConfig.minimumUpfrontAmountPercentageStep;
      let percentage = this.getClosestHigherMultiple(number, divisor);
      let result;
      if (percentage < this.config[this.paymentType].flexiblePaymentPlanConfig.minimumUpfrontAmountPercentage) {
        result = this.config[this.paymentType].flexiblePaymentPlanConfig.minimumUpfrontAmountPercentage
      } else {
        result = percentage
      }
      return result;
    },

  },
  watch: {
    config(value) {
      if (value?.creditBalance === 0) {
        this.applyUserCreditBalance = false;
      }
    },
    useCardZilla(value) {
      this.setFlexibleFees();
    },
    applyUserCreditBalance(val) {
      if (this.creditAmountFinanceable > this.creditBalanceToUse) {
        this.flexiConfig.upfrontAmountPercent = this.flexiUpfrontAmountPercent;
      }
    },
    "flexiConfig.upfrontAmountPercent"(val) {
      this.setFlexibleFees();
      this.setRangeTooltip();
    },
    "flexiConfig.numberOfRepayment"(val) {
      this.setFlexibleFees();
    },
    productAmount: {
      immediate: true,
      handler(val) {
        if (this.creditAmountFinanceable > this.creditBalanceToUse) {
          this.flexiConfig.upfrontAmountPercent = this.flexiUpfrontAmountPercent;
        } else {
          this.flexiConfig.upfrontAmountPercent = this.config[this.paymentType].flexiblePaymentPlanConfig.minimumUpfrontAmountPercentage;
        }
        this.setRangeTooltip();
      }
    },
  },

  filters: {
    formatDueDate(date) {
      //Get 1 day in milliseconds
      // var one_day = 1000 * 60 * 60 * 24;
      var one_month = 1000 * 60 * 60 * 24 * 28;

      // Convert both dates to milliseconds
      var date1_ms = new Date();
      var date2_ms = new Date(date).getTime();

      // Calculate the difference in milliseconds
      var difference_ms = date2_ms - date1_ms;
      let summary = "";

      summary = `${Math.round(difference_ms / one_month)}${Math.round(difference_ms / one_month) == 1 ? " month" : " months"}`;
      return summary;
    },
  },

  mounted() {
    if (Object.keys(this.config).length === 0) {
      this.getCalculatorData();
    } else {
      this.setFlexibleFees();
      if (this.config?.creditBalance <= 0) {
        this.applyUserCreditBalance = false;
      }
    }
    document.addEventListener("DOMContentLoaded", this.setRangeTooltip());
  },

  methods: {
    ...mapActions("loading", ["setLoading"]),
    ...mapActions("card", ["setFlexiFee", "setDefaultFlexi"]),
    ...mapActions("notification", ["showAlert", "showToast"]),
    ...mapActions("userInfo", ["getAllCustomerFees"]),

    handleInputChange(e) {
      this.setRangeTooltip();
    },

    // Get tooltip content for fees
    tooltipContent() {
      let content;
      let fees = this.selectedPayment?.feeDetails?.fees[0].percentage;
      content = `${fees}% for using this payment plan`;
      return content
    },

    // Product amount plus interest
    totalProductAmount() {
      if (this.useCardZilla || this.selectedPayment?.feeDetails?.fees[0]?.percentage > 0) {
        return parseInt(this.productAmount) + this.calulateFees(this.selectedPayment?.feeDetails?.fees) || 0;
      } else {
        return this.productAmount || 0;
      }
    },

    setRangeTooltip() {
      let range = document.getElementById("range");
      let rangeV = document.getElementById("rangeV");
      const newValue = Number(((this.flexiConfig.upfrontAmountPercent - this.flexiUpfrontAmountPercent) * 100) / (range.max - this.flexiUpfrontAmountPercent)),
        newPosition = 10 - newValue * 0.2;
      rangeV.innerHTML = `<span>${Math.round(this.flexiConfig.upfrontAmountPercent * 10) / 10}%</span>`;
      rangeV.style.left = `calc(${newValue}% + (${newPosition}px))`;
    },

    // get Amount Financeable for each payment method
    creditAmountFinanceablePerPlan(paymentPlan) {
      let paymentAmountPercent = 100 - paymentPlan.paymentSchedule[0].paymentAmountPercent;
      let amountFinanceable = (paymentAmountPercent / 100) * this.totalProductAmount();
      return amountFinanceable;
    },


    setFlexibleFees() {
      let source = this.useCardZilla ? this.allCreditCardFees : this.allCheckoutFees;
      let fee;
      if (this.flexiUpfrontAmountPercent >= this.updatedUpfrontAmountPercentage) {
        fee = source[this.flexiUpfrontAmountPercent][(this.flexiConfig.numberOfRepayment - 1)];
      } else {
        fee = source[this.updatedUpfrontAmountPercentage][(this.flexiConfig.numberOfRepayment - 1)];
      }
      this.setFlexiFee({ fee: fee, type: this.paymentType })
        .then(() => {
          this.setLoading(false);
          this.loadPage = false;
          this.$nextTick(() => {
            this.setRangeTooltip();
          });

        });

    },

    getClosestHigherMultiple(number, multiple) {
      let remainder = number % multiple;

      if (remainder === 0) {
        return number;
      } else {
        return number + (multiple - remainder);
      }
    },

    getFees(payload) {
      this.getAllCustomerFees(payload)
        .then((res) => {
          this.setFlexibleFees();
        })
    },

    // Disable credit plans
    disablePlan(paymentPlan) {
      if (this.creditAmountFinanceablePerPlan(paymentPlan) > this.creditBalanceToUse) {
        if (this.activePaymentOption === paymentPlan.name) {
          this.activePaymentOption = this.config[this.paymentType].flexiblePaymentPlanConfig.name;
        }
        return "opacity-50 pointer-events-none";
      } else {
        return "opacity-100 pointer-events-auto cursor-pointer";
      }
    },


    handlePaymentType() {
      if (this.product.amount) {
        this.showModal = true;
      } else {
        this.showToast({
          display: true,
          description: "Please input a product amount within the range",
          icon: "error",
        });
      }
    },

    selectPayment(e) {
      this.activePaymentOption = e;
      this.showModal = false;
      if (e === this.config[this.paymentType].flexiblePaymentPlanConfig.name) {
        if (this.activePaymentType !== PAYMENT_PLANS.flexible.toLowerCase()) {
          this.flexiConfig.upfrontAmountPercent = this.flexiUpfrontAmountPercent;
        }
        this.$nextTick(() => {
          this.setRangeTooltip();
        });
      }
    },

    getAmountToPay(itemPercent, paymentIndex) {
      let amountToPay;
      if (this.applyUserCreditBalance || this.productAmount > this.creditBalanceToUse) {
        if (this.creditAmountFinanceable > this.creditBalanceToUse) {
          let percentage = this.flexiConfig.upfrontAmountPercent;
          if (paymentIndex === 0) {
            let interest = this.calulateFees(this.selectedPayment.feeDetails.fees);
            let amount = this.productAmount * (percentage / 100);
            amountToPay = amount + interest;
          } else {
            let first_payment = this.productAmount * (percentage / 100)
            amountToPay = (this.productAmount - first_payment) / (this.currentPaymentSchedule.length - 1);
          }
        } else if (this.activePaymentType === PAYMENT_PLANS.flexible.toLowerCase()) {
          amountToPay = this.getFlexibleAmountToPay(itemPercent, paymentIndex);
        } else {
          if (paymentIndex === 0) {
            let interest = this.calulateFees(this.selectedPayment.feeDetails.fees);
            let amount = (itemPercent / 100) * this.productAmount;
            amountToPay = amount + interest;
          } else {
            amountToPay = (itemPercent / 100) * this.productAmount;
          }
        }
      } else if (this.activePaymentType === PAYMENT_PLANS.flexible.toLowerCase()) {
        amountToPay = this.getFlexibleAmountToPay(itemPercent, paymentIndex);
      }
      else {
        amountToPay = (itemPercent / 100) * this.productAmount;
      }
      return amountToPay;
    },

    getFlexibleAmountToPay(itemPercent, paymentIndex) {
      let amountToPay;
      if (paymentIndex === 0) {
        let difference = this.totalProductAmount() - this.productAmount;
        let first_payment = (this.flexiConfig.upfrontAmountPercent / 100) * this.productAmount;
        amountToPay = first_payment + difference;
      } else {
        amountToPay = (itemPercent / 100) * this.productAmount;
      }
      return amountToPay;
    },

    calulateFees(feeData) {
      let maxAmount = this.selectedPayment.feeDetails.feeCap;
      let feeToPay = 0;
      feeData.forEach((fee) => {
        if (fee.upperLimit) {
          if (this.productAmount < fee.upperLimit) {
            feeToPay = (fee.percentage / 100) * this.productAmount + fee.fixed ? fee.fixed : 0;
          }
        } else if (fee.lowerLimit) {
          if (this.productAmount > fee.lowerLimit) {
            feeToPay = (fee.percentage / 100) * this.productAmount + fee.fixed ? fee.fixed : 0;
          }
        } else {
          const fixedFee = fee.fixed ? fee.fixed : 0;
          feeToPay = (fee.percentage / 100) * this.productAmount + fixedFee;
        }
      });
      if (maxAmount) {
        return feeToPay > maxAmount ? maxAmount : feeToPay;
      } else {
        return feeToPay;
      }
    },

    getCalculatorData() {
      this.loadPage = true;
      this.setLoading(true);
      GET_CALCULATOR_DATA()
        .then((res) => {
          this.$store.commit("card/SET_CALCULATOR_DATA", res.data?.data);
          // if (res.data?.data[this.paymentType].defaultFlexiblePaymentPlan) {
          //   this.setDefaultFlexi();
          // };
          this.getFees({ isCreditCard: true });
          this.getFees({ isCreditCard: false });
          this.flexiConfig.upfrontAmountPercent = this.flexiUpfrontAmountPercent;
        })
        .catch(() => {
          this.loadPage = false;
          this.setLoading(false);
        });
    },

    capitalize(word) {
      let string = word.toLowerCase();
      return string.charAt(0).toUpperCase() + string.slice(1);
    },

    calculateRepaymentDate(date, type, timeUnit, duration) {
      if (type === "before") {
        return dayjs(date).subtract(duration, timeUnit.toLowerCase()).toDate();
      } else {
        return dayjs(date).add(duration, timeUnit.toLowerCase()).toDate();
      }
    },

    formatMoney(value) {
      let formattedNumber;
      if (value === null || value === undefined) {
        formattedNumber = 0;
      } else {
        formattedNumber = new Intl.NumberFormat("en-NG", {
          style: "currency",
          currency: "NGN",
          currencyDisplay: "symbol",
        })
          .format(value)
          .replace(/(\.|,)00$/g, "");
      }

      return formattedNumber;
    },
  },
};
</script>
<style scoped>
.dot {
  height: 20px;
  width: 20px;
  border-radius: 50%;
  border: 2px solid #d5ccff;
}

.dot-active {
  height: 20px;
  width: 20px;
  border-radius: 50%;
  border: 2px solid #d5ccff;
  padding: 2px;
}

.custom-select {
  border: 1px solid #EDEDF0;
  border-radius: 5px;
}

.calculator-container {
  max-width: 36.563rem;
  margin: auto;
}

.card-heading .top {
  color: #1e1d20;
  font-size: 26px;
}

.timeline-grid {
  position: relative;
  /* display: grid;
    grid-template-columns: repeat(4, minmax(0, 1fr)); */
  min-height: 100%;
  margin-top: 10px;
  overflow: auto;
  padding: 5px 0;
  box-shadow: inset -7px 0px 9px -7px rgba(0, 0, 0, 0.06);

  /* box-shadow: inset -11px 0px 12px 0px rgba(0, 0, 0, 0.06), inset 10px 0px 9px -3px rgba(0, 0, 0, 0.06); */
}

.timeline-grid::-webkit-scrollbar {
  display: none;
}

.timeline-grid::after {
  content: " ";
  background-color: transparent;
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  /* box-shadow: inset 0px -7px 9px -7px rgb(0 0 0 / 80%); */
}

/* Hide scrollbar for IE, Edge and Firefox */
.timeline-grid {
  -ms-overflow-style: none;
  /* IE and Edge */
  scrollbar-width: none;
  /* Firefox */
}

.timeline-stretch {
  display: flex;
  align-items: center;
  margin: 8px 0;
}

.timeline-item .dot {
  height: 6px;
  width: 6px;
  border-radius: 50%;
  background: #918e9c;
}

.timeline-item .line {
  border: 1px solid #e0dfe3;
  flex-grow: 1;
}

.timeline-amount {
  padding: 0 4px;
}

.heading-description {
  margin-top: 24px;
}

.calculator-input-section {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.calculator-input-section .description {
  width: 60%;
}

.calculator-input {
  padding: 14px 1rem;
  border: 1px solid #ededf0;
  border-radius: 8px;
  background: white;
  font-size: 18px;
  outline: none;
  max-width: 7.5rem;
  -webkit-appearance: none;
}

.payment-options {
  display: grid;
  grid-template-columns: 2rem 1fr;
  grid-gap: 5px;
  align-items: center;
}

.currency-input-custom-class {
  border: 1px solid #ededf0 !important;
  border-radius: 4px;
  padding: 12px 10px;
  background: white !important;
  outline: none;
}

input[type="range"] {
  -webkit-appearance: none;
  margin: 20px 0;
  width: 100%;
}

input[type="range"]:focus {
  outline: none;
}

input[type="range"]::-webkit-slider-runnable-track {
  width: 100%;
  height: 4px;
  cursor: pointer;
  animate: 0.2s;
  background: #ebeaee;
  border-radius: 25px;
}

input[type="range"]::-webkit-slider-thumb {
  height: 20px;
  width: 20px;
  border-radius: 50%;
  background: #fff;
  box-shadow: 0 0 4px 0 rgba(0, 0, 0, 1);
  cursor: pointer;
  -webkit-appearance: none;
  margin-top: -8px;
}

input[type="range"]:focus::-webkit-slider-runnable-track {
  background: #ebeaee;
}

.range-wrap {
  /* width: 500px; */
  position: relative;
}

.range-value {
  position: absolute;
  top: -50%;
}

.range-value {
  /* min-width: 40x; */
  padding: 2px 4px 3px 4px;
  height: 26px;
  line-height: 24px;
  text-align: center;
  color: #6956c8;
  background: #fff;
  font-size: 12px;
  display: block;
  border: 1px solid #6956c8;
  position: absolute;
  left: 10px;
  transform: translate(-50%, 0);
  border-radius: 6px;
}

.range-value:before {
  content: "";
  position: absolute;
  width: 0;
  height: 0;
  border-top: 10px solid #fff;
  border-left: 2px solid #6956c8;
  border-right: 2px solid #6956c8;
  top: 100%;
  left: 50%;
  margin-left: -2px;
  margin-top: -1px;
}

input[type="range"].range::-webkit-slider-thumb {
  width: 20px;
  height: 20px;
  border-radius: 1em;
  background: #6956c8;
  border: none;
  box-shadow: 0 0 2px black;
}

input[type="range"].range::-moz-range-thumb {
  width: 20px;
  height: 20px;
  border-radius: 1em;
  background: #6956c8;
  border: none;
  box-shadow: 0 0 2px black;
}
</style>
