var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "grid rounded bg-layoutGrey-2 w-full",
      class: ["grid-cols-" + _vm.options.length],
    },
    _vm._l(_vm.options, function (option) {
      return _c(
        "div",
        {
          key: option,
          staticClass: "shadow rounded cursor-pointer transition-all",
          class: { active: option === _vm.value },
          on: {
            click: function ($event) {
              return _vm.$emit("input", option)
            },
          },
        },
        [
          _c("p", { staticClass: "text-center text-xs font-semibold py-2" }, [
            _vm._v(_vm._s(option)),
          ]),
        ]
      )
    }),
    0
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }