export const PAYMENT_PLANS = {
  twice: "pay-twice",
  full: "pay-in-full",
  thrice: "pay-in-three",
  flexible: 'flexible'
};

export const PRETTY_PAYMENT_PLANS = {
  twice: "Pay twice",
  full: "Pay in full",
  thrice: "Pay in 3",
  flexible: 'Flexible payment'
};
  